/* eslint jsx-a11y/anchor-is-valid: 0 */
import React from 'react'
import Link from 'gatsby-link'
import {Helmet} from 'react-helmet'
import { JsonLdSiteNavigation } from 'blog-components'

import Search from '../Search'
import { StaticQuery, graphql } from 'gatsby'

import './style.scss'

class SiteNavi extends React.Component {
  buildCategoryNavItems(categories) {
    let items = []

    categories.forEach((element, index) => {
      items.push(
        <li className="nav-item" key={index}>
          <a className="nav-link" href={element.node.slug}>
            {element.node.title}
          </a>
        </li>
      )
    })

    return items
  }

  getCategoryDropdownItems(categoryNavItems) {
    let items = []

    categoryNavItems.forEach((categoryNavItem, index) => {
      items.push(
        <a key={index} className="dropdown-item" href={categoryNavItem.url}>
          {categoryNavItem.name}
        </a>
      )
    })

    return items
  }

  buildSiteNameItems(site, categoryNavItems) {
    let items = []

    categoryNavItems.forEach((categoryNavItem, index) => {
      items.push({
        url: site.siteMetadata.siteUrl + categoryNavItem.url,
        name: categoryNavItem.name,
      })
    })

    return items
  }

  render() {
    const { logo, site } = this.props

    let siteNavItems = [
      {
        name: 'Climbing Gear',
        url: site.siteMetadata.siteUrl + '/best-climbing-gear/',
      },
      {
        name: 'Climbing Culture',
        url: site.siteMetadata.siteUrl + '/climbing-culture/',
      },
      {
        name: 'Climbing Skills',
        url: site.siteMetadata.siteUrl + '/climbing-skills/',
      },
    ]

    return (
      <div>
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify(
              JsonLdSiteNavigation.generate(
                site.siteMetadata.siteUrl,
                siteNavItems
              )
            )}
          </script>
        </Helmet>
        {/* <ul className="nav navbar-dark nav-fill bg-primary justify-content-center text-center">
          <li className="nav-item">
            <a className="nav-link no-gutters" href="/climbing-olympics/">
              <span className="navbar-text text-white display-6">
                Must Read - A Complete Guide To Climbing At The Tokyo 2020
                Olympics
              </span>
            </a>
          </li>
        </ul> */}

        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <Link to="/">
                <img
                  id="main-logo"
                  src={logo}
                  alt={site.siteMetadata.brand + ' Logo'}
                />
              </Link>
            </div>
            <div className="col-md-6">
              <StaticQuery
                query={graphql`
                  query SearchIndexQuery {
                    siteSearchIndex {
                      index
                    }
                  }
                `}
                render={data => (
                  <Search searchIndex={data.siteSearchIndex.index} />
                )}
              />
            </div>
          </div>
          <div className="row no-gutters pb-3">
            <div className="col-md-10 offset-md-1">
              <ul className="nav nav-pills nav-fill">
                <li className="nav-item">
                  <a className="nav-link d-md-none" href="/best-climbing-gear/">
                    Gear
                  </a>
                  <a
                    className="nav-link d-none d-md-block"
                    href="/best-climbing-gear/"
                  >
                    Climbing Gear
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link d-md-none" href="/climbing-culture/">
                    Culture
                  </a>
                  <a
                    className="nav-link d-none d-md-block"
                    href="/climbing-culture/"
                  >
                    Climbing Culture
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link d-md-none" href="/climbing-skills/">
                    Skills
                  </a>
                  <a
                    className="nav-link d-none d-md-block"
                    href="/climbing-skills/"
                  >
                    Climbing Skills
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default SiteNavi

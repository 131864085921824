import React from 'react'
import Link from 'gatsby-link'
import './style.scss'

import * as messages from '../../messages.json'

class Footer extends React.Component {
  getAuthorsList() {
    const { authors } = this.props
    let components = []

    authors.forEach((author, index) => {
      components.push(
        <li key={index} className="py-1">
          <Link className="text-muted" to={author.node.slug}>
            {author.node.name}
          </Link>
        </li>
      )
    })

    return components
  }

  render() {
    const { logo } = this.props
    return (
      <footer className="mt-3 py-5 text-center text-white">
        <div className="row no-gutters">
          <div className="col-md-6 py-2">
            <h5>{messages.layouts.index.components.footer.col1.title}</h5>
            <ul className="list-unstyled text-small">
              {this.getAuthorsList()}
            </ul>
          </div>
          <div className="col-md-6 py-2">
            <h5>{messages.layouts.index.components.footer.col2.title}</h5>
            <ul className="list-unstyled text-small">
              <li className="py-1">
                <Link className="text-muted" to="/about/">
                  {messages.layouts.index.components.footer.col2.items[0]}
                </Link>
              </li>
              <li className="py-1">
                <Link className="text-muted" to="/terms-and-conditions/">
                  {messages.layouts.index.components.footer.col2.items[1]}
                </Link>
              </li>
              <li className="py-1">
                <Link className="text-muted" to="/privacy-policy/">
                  {messages.layouts.index.components.footer.col2.items[2]}
                </Link>
              </li>
              <li className="py-1">
                <Link className="text-muted" to="/contactus/">
                  {messages.layouts.index.components.footer.col2.items[3]}
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="row justify-content-center no-gutters">
          <div className="col-4">
            <Link to="/">
              <img
                className="img-fluid bottom-logo"
                src={logo}
                alt="The Climbing Guy Logo"
              />
            </Link>
            <small className="d-block mb-3 text-muted text-center">
              {messages.layouts.index.components.footer.ownership.date}
            </small>
            <small className="d-block mb-3 text-muted text-center">
              {messages.layouts.index.components.footer.ownership.disclaimer}
            </small>
          </div>
        </div>

        <div className="row justify-content-center no-gutters">
          <small className="d-block mb-3 text-muted text-center">
            <Link className="text-muted" to="/terms-and-conditions/">
              {messages.layouts.index.components.footer.col2.items[1]}
            </Link>
            <span> | </span>
            <Link className="text-muted" to="/privacy-policy/">
              {messages.layouts.index.components.footer.col2.items[2]}
            </Link>
          </small>
        </div>
      </footer>
    )
  }
}

export default Footer

import React from 'react'
import emergence from 'emergence.js'
import {Helmet} from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import PropTypes, { object, array } from 'prop-types'
import 'lazysizes'

import SiteNavi from '../SiteNavi'
import Footer from '../Footer'
import { ScrollToTopOfPage } from 'blog-components'
// eslint-disable-next-line
import SiteInformation from '../../fragments/SiteInformationFragment'

import 'animate.css/animate.css'
import 'font-awesome/css/font-awesome.css'
import 'blog-components/dist/index.css'
import './gatstrap.scss'

if (typeof window !== 'undefined') {
  window.jQuery = window.$ = require('jquery')
  require('bootstrap')
  require('popper.js')
}

export default class Layout extends ScrollToTopOfPage {
  componentDidMount() {
    emergence.init({ reset: false })
  }

  componentDidUpdate() {
    emergence.init({ reset: false })
  }

  render() {
    const { children } = this.props

    return (
      <StaticQuery
        query={graphql`
          query LogoImageQuery {
            site {
              ...SiteInformation
            }
            navLogo: file(relativePath: { eq: "main-logo.svg" }) {
              publicURL
            }
            footerLogo: file(relativePath: { eq: "main-logo-white.svg" }) {
              publicURL
            }
            allPosts: allContentfulBlogPost(filter: { visible: { eq: true } }) {
              posts: edges {
                post: node {
                  slug
                }
              }
            }
            allContentfulPerson(filter: { name: { ne: "Dummy Author" } }) {
              edges {
                node {
                  name
                  slug
                }
              }
            }
            categories: allContentfulCategory(filter: {level: {eq: 1}, visible: {eq: true}}) {
              edges {
                node {
                  title
                  slug
                  subcategories {
                    title
                    slug
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <div>
            <Helmet>
              {/* <script
                type="text/javascript"
                src={
                  '//s7.addthis.com/js/300/addthis_widget.js#pubid=' +
                  data.site.siteMetadata.addThisId
                }
                defer="defer"
              /> */}
              <script type="text/javascript">
                {`
                  var OneSignal = window.OneSignal || [];
                  OneSignal.push(function() {
                  OneSignal.init({
                    appId: "${data.site.siteMetadata.onesignal}",
                    autoRegister: false,
                    notifyButton: {
                      enable: true,
                    },
                  });
                  OneSignal.registerForPushNotifications(); // shows native browser prompt
                });
                  `}
              </script>
            </Helmet>
            <SiteNavi
              logo={data.navLogo.publicURL}
              posts={data.allPosts}
              site={data.site}
              categories={data.categories}
            />
            {children}
            <Footer
              logo={data.footerLogo.publicURL}
              authors={data.allContentfulPerson.edges}
            />
          </div>
        )}
      />
    )
  }
}

Layout.propTypes = {
  children: PropTypes.oneOfType([array, object]).isRequired,
}

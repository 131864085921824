import React, { Component } from 'react'
import { Index } from 'elasticlunr'
import Link from 'gatsby-link'

import './style.scss'

const MAX_RESULTS = 5

// Search component
export default class Search extends Component {
  constructor(props) {
    super(props)
    this.state = {
      query: ``,
      results: [],
    }
  }

  render() {
    return (
      <div className="px-3">
        <input
          className="form-control mr-sm-2"
          type="search"
          placeholder="Search..."
          value={this.state.query}
          onChange={this.search}
          aria-label="Search"
        />
        
        <ul className="search-result list-group text-left">
          {this.state.results.map(page => (
            <li className="list-group-item" key={page.id}>
              <Link
                to={'/' + page.path}
                onClick={() => {
                  this.setState({ query: ``, results: [] })
                }}
              >
                {page.title}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    )
  }
  getOrCreateIndex = () =>
    this.index
      ? this.index
      : // Create an elastic lunr index and hydrate with graphql query results
        Index.load(this.props.searchIndex)

  search = evt => {
    const query = evt.target.value
    this.index = this.getOrCreateIndex()
    this.setState({
      query,
      // Query the index with search string to get an [] of IDs
      results: this.index
        .search(query, {})
        // Map over each ID and return the full document
        .filter(({ ref }) => {
          let doc = this.index.documentStore.getDoc(ref)
          return doc.path !== '/style-guide/'
        })
        .map(({ ref }) => {
          return this.index.documentStore.getDoc(ref)
        })
        .slice(0, MAX_RESULTS),
    })
  }
}
